body {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

#root {
  margin-top: 3rem;
}

.the-best-text {
  text-align: center;
}

#input {
  height: 3rem;
  font-size: 2rem;
  text-align: center;
  width: 90%;
}

.letter-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
}

.letter {
  max-height: 80px;
  max-width: 80px;
}
